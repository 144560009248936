(function ($) {

    var $win = $(window);
    var $doc = $(document);
    var winHeight = $win.height();

    $('.testimonial-carousel').owlCarousel({
        items: 1,
        loop: true,
        nav: true,
        dots: false
    });

    $('.faq-accordion .question').click(function (e) {
        if ($(this).parent().hasClass('open')) {
            $('.faq-accordion').removeClass('open');
        } else {
            $('.faq-accordion').removeClass('open');
            $(this).parent().toggleClass('open');
            e.preventDefault();
        }
    });

    $('.equalizer').equalizer({
        columns: '> div .bordered-column'
    });

    $('#mobile-button').click(function (e) {
        e.preventDefault();
        $('body').toggleClass('nav-open');
    });

    $('body').click(function (e) {
        if ($(e.target).closest('.mobile-navigation').length === 0) {
            $('body').removeClass('nav-open');
        }
    });

    $('.service-eq').equalizer({
        columns: '> div .service-container'
    });

    $('.news-content img').wrap('<div class="news-image"></div>');

    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });

    $('.inline-popup').magnificPopup({
        type:'inline',
        midClick: true
    });

    if(!$.cookie('visited')){
        $('body').addClass('sticky-active');
    }

    $('.close-sticky').click(function(e) {
        e.preventDefault();
        $('body').removeClass('sticky-active');
        $.cookie('visited', 'yes', {expires: 7});
    });

    $('.owl-slideshow').owlCarousel({
        items: 1,
        loop: true,
        nav: true,
        margin: 30
    });

    $('.impact-content-btn').click(function(e) {
        e.preventDefault();
        $('#trad-content').hide();
        $('.step-2').hide();
        $('#impact-content').show();
        $('html, body').animate({scrollTop: $('#impact-content').offset().top -180 }, 'slow');
    });

    $('.trad-content-btn').click(function(e) {
        e.preventDefault();
        $('#impact-content').hide();
        $('.step-2').show();
        $('#trad-content').show();

       $('.isotope-filter').isotope({
            itemSelector: '.news-post',
            layoutMode: 'fitRows'
        });
        $('html, body').animate({scrollTop: $('.step-2').offset().top -100 }, 'slow');
    });

    $(".reset-button").click(function(){
        $(".isotope-filter").isotope({
            filter: '*'
        });
        $('#form-filter').trigger("reset");
    });

    // subnav
    $('.nav a').on('click', function(e){
        if ( $win.width() < 992 ) {
            var $parent = $(this).parent();
            var $siblingUl = $parent.find(' > ul');

            if ( $siblingUl.length && !$parent.hasClass('open') ){
                $parent.addClass('open').siblings().removeClass('open').find('.open').removeClass('open');

                e.preventDefault();
            }
        }
    });


    var d = new Date();
    var hour = d.getHours();
    var $time_of_day = $('#time-of-day');

    if(hour >= 4 && hour < 12){
        $time_of_day.text('morning');
    } else if(hour >= 12 && hour < 17){
        $time_of_day.text('afternoon');
    } else {
        $time_of_day.text('evening');
    }

    /**
     * Animate on scroll
     */

    function performanceData(topPosition) {
        $('.section').each(function() {
            var element = $(this),
                winH = $win.height();

            if ( topPosition > element.offset().top ) {
                element.addClass('animated');
            }

            for (var i = element.length - 1; i >= 0; i--) {
                var currentElementTop = element.eq(i).offset().top - winH,
                    currentScrollTop = topPosition;

                if ( topPosition > currentElementTop ) {
                    element.addClass('animated');
                }
            }
        });
    }

    $win.on('load scroll', function(){
        var topPosition = $win.scrollTop();

        performanceData(topPosition);
    });

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 1) {
            $('header').addClass('scrolled');
        } else {
            $('header').removeClass('scrolled');
        }
    });

    $(window).load(function() {
        $('.equalize').equalizer({
            columns: '> div'
       });

        var $portfolio = $('.isotope-filter');

        $portfolio.isotope({
            itemSelector: '.news-post',
            layoutMode: 'fitRows'
        });

        $('#form-filter').on('submit', function (e) {
            e.preventDefault();
            $portfolio.isotope({filter: function () {
                var season_inputs = $('.season-checkbox:checked').map(function(){
                    return this.value;
                }).get();
                var item_seasons = $(this).data('seasons').split(',');

                var characteristics_inputs = $('.characteristics-checkbox:checked').map(function(){
                    return this.value;
                }).get();
                var item_characteristics = $(this).data('characteristics').split(',');

                var filter = true; // []

                $.each(season_inputs, function(idx, ele){
                    if(item_seasons.indexOf(ele) === -1){
                        filter = false;
                    }
                });

                $.each(characteristics_inputs, function(idx, ele){
                    if(item_characteristics.indexOf(ele) === -1){
                        filter = false;
                    }
                });

                return filter;

            }});

            $('html, body').animate({scrollTop: $('#trad-content').offset().top -200 }, 'slow');
        });

    });

})(jQuery);




